const { sha3, BN } = require("web3-utils");
const abiCoder = require("web3-eth-abi");

function _typeToString(input) {
    if (input.type === "tuple") {
        return "(" + input.components.map(_typeToString).join(",") + ")";
    }
    return input.type;
}

export class ABIDecoder {
    constructor() {
        this.state = {
            savedABIs: [],
            methodIDs: {},
            keepNonDecodedLogs: false
        };
    }

    getABIs() {
        return this.state.savedABIs;
    }

    addABI(abiArray) {
        if (Array.isArray(abiArray)) {
            // Iterate new abi to generate method id"s
            const inst = this;
            abiArray.map(function (abi) {
                if (abi.name) {
                    const signature = sha3(
                        abi.name +
                        "(" +
                        abi.inputs
                            .map(_typeToString)
                            .join(",") +
                        ")"
                    );
                    if (abi.type === "event") {
                        inst.state.methodIDs[signature.slice(2)] = abi;
                    } else {
                        inst.state.methodIDs[signature.slice(2, 10)] = abi;
                    }
                }
            });

            this.state.savedABIs = this.state.savedABIs.concat(abiArray);
        } else {
            throw new Error("Expected ABI array, got " + typeof abiArray);
        }
    }

    removeABI(abiArray) {
        if (Array.isArray(abiArray)) {
            // Iterate new abi to generate method id"s
            const inst = this;
            abiArray.map(function (abi) {
                if (abi.name) {
                    const signature = sha3(
                        abi.name +
                        "(" +
                        abi.inputs
                            .map(function (input) {
                                return input.type;
                            })
                            .join(",") +
                        ")"
                    );
                    if (abi.type === "event") {
                        if (inst.state.methodIDs[signature.slice(2)]) {
                            delete inst.state.methodIDs[signature.slice(2)];
                        }
                    } else {
                        if (inst.state.methodIDs[signature.slice(2, 10)]) {
                            delete inst.state.methodIDs[signature.slice(2, 10)];
                        }
                    }
                }
            });
        } else {
            throw new Error("Expected ABI array, got " + typeof abiArray);
        }
    }

    getMethodIDs() {
        return this.state.methodIDs;
    }

    decodeLogs(logs) {
        const state = this.state;
        return logs.filter(log => log.topics.length > 0).map((logItem) => {
            const methodID = logItem.topics[0].slice(2);
            const method = state.methodIDs[methodID];
            if (method) {
                const logData = logItem.data;
                let decodedParams = [];
                let dataIndex = 0;
                let topicsIndex = 1;

                let dataTypes = [];
                method.inputs.map(function (input) {
                    if (!input.indexed) {
                        dataTypes.push(input.type);
                    }
                });

                // in some cases, if we have 2 event log, that has the same signature but indexed field is different
                // for example, 
                // + KAP20 Approval has [indexed address owner, indexed address spender, uint256 amount]
                // + KAP721 Approval has [indexed address owner, indexed address spender, indexed uint256 tokenID] 
                // the last event field has different indexed, if we decode KAP721 Approval with KAP20 abi, it expects that the 3rd field must be indexed
                // but actually it does not, so logData would be null or undefine. this may cause exception in decodeParameter function call 
                let decodedData = null;
                try {
                    decodedData = abiCoder.decodeParameters(
                        dataTypes,
                        logData.slice(2)
                    );
                }
                catch (err) {
                    return;
                }

                // Loop topic and data to get the params
                method.inputs.map(function (param) {
                    let decodedP = {
                        name: param.name,
                        type: param.type,
                    };

                    if (param.indexed) {
                        decodedP.value = logItem.topics[topicsIndex];
                        topicsIndex++;
                    } else {
                        decodedP.value = decodedData[dataIndex];
                        dataIndex++;
                    }

                    if (param.type === "address") {
                        decodedP.value = decodedP.value.toLowerCase();
                        // 42 because len(0x) + 40
                        if (decodedP.value.length > 42) {
                            let toRemove = decodedP.value.length - 42;
                            let temp = decodedP.value.split("");
                            temp.splice(2, toRemove);
                            decodedP.value = temp.join("");
                        }
                    }

                    if (
                        param.type === "uint256" ||
                        param.type === "uint8" ||
                        param.type === "int"
                    ) {
                        // ensure to remove leading 0x for hex numbers
                        if (typeof decodedP.value === "string" && decodedP.value.startsWith("0x")) {
                            decodedP.value = new BN(decodedP.value.slice(2), 16).toString(10);
                        } else {
                            decodedP.value = new BN(decodedP.value).toString(10);
                        }

                    }

                    decodedParams.push(decodedP);
                });

                return {
                    name: method.name,
                    events: decodedParams,
                    address: logItem.address,
                };
            }
        }).filter(decoded => state.keepNonDecodedLogs || decoded);
    }

    keepNonDecodedLogs() {
        this.state.keepNonDecodedLogs = true
    }

    discardNonDecodedLogs() {
        this.state.keepNonDecodedLogs = false
    }
}